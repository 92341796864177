import { Controller } from 'stimulus';

export default class extends Controller {

  trackClick(event) {
    let target = event.currentTarget
    let name = target.dataset.tagName

    TurbolinksAdapter.hitTag(name);
  }
}
