window.TurbolinksAdapter = {
  isNativeHandlerAvailable: function () {
    return (typeof nua !== "undefined" && nua !== null) || (window.webkit != null);
  },
  postMessage: function (data) {
    if (typeof nua !== "undefined" && nua !== null) {
      return nua.postMessage(JSON.stringify(data));
    } else if (window.webkit != null) {
      return window.webkit.messageHandlers.nua.postMessage(data);
    }
  },
  visit: function (url, options) {
    if (options == null) {
      options = {};
    }

    this.action = 'advance';
    if (options.action != null) {
      this.action = options.action;
    }

    this.fullReload = false;
    if (options.fullReload != null) {
      this.fullReload = options.fullReload;
    }

    this.authenticateBeforeLoad = false;
    if (options.authenticateBeforeLoad != null) {
      this.authenticateBeforeLoad = options.authenticateBeforeLoad;
    }

    this.authenticationCodeProvider = null;
    if (options.authenticationCodeProvider != null) {
      this.authenticationCodeProvider = options.authenticationCodeProvider;
    }

    this.turbolinksEnabled = true;
    if (options.turbolinksEnabled != null) {
      this.turbolinksEnabled = options.turbolinksEnabled;
    }

    this.openInBrowser = false;
    if (options.openInBrowser != null) {
      this.openInBrowser = options.openInBrowser;
    }

    if (this.isNativeHandlerAvailable() && (this.getNuaAction(this.action) != null)) {
      console.log("Turbolinks.visit, url = " + url + ", action = " + this.getNuaAction(this.action));
      return this.postMessage({
        name: 'visitProposed',
        data: {
          location: url,
          action: this.getNuaAction(this.action),
          fullReload: this.fullReload,
          authenticateBeforeLoad: this.authenticateBeforeLoad,
          authenticationCodeProvider: this.authenticationCodeProvider,
          turbolinksEnabled: this.turbolinksEnabled,
          openInBrowser: this.openInBrowser
        }
      });
    } else {
      if (this.action == 'resetRoot') {
        this.action = 'replace';
      } else if (this.action !== 'advance' || this.action !== 'replace') {
        this.action = 'advance';
      }
      console.log("Turbolinks.visit, url = " + url + ", action = " + this.action + ", options = " + JSON.stringify(options));
      return Turbolinks.visit(url, {
        'action': this.action
      });
    }
  },
  onLoginAttempt: function(email, password) {
    return this.postMessage({
      name: 'loginAttempt',
      data: {
        email: email,
        password: password
      }
    })
  },
  isLoggedIn: function() {
    if (this.isNativeHandlerAvailable()) {
      return {
        name: 'isLoggedIn',
        data: {
          result: (document.querySelector("meta[name='user-authentication']").getAttribute("content") === 'true'),
          userId: document.querySelector("meta[name='user-authentication']").getAttribute("data-user-id")
        }
      };
    }
  },
  login: function(email, password) {
    var xhr = new XMLHttpRequest();
    xhr.open("POST", '/sessions.js', true);
    xhr.setRequestHeader('Accept', 'application/javascript');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        var script = document.createElement('script');
        script.text = xhr.response;
        document.head.appendChild(script).parentNode.removeChild(script);
      }
    };
    xhr.send(this.serialize({ login_form: { email: email, password: password } }))
  },
  logout: function() {
    var xhr = new XMLHttpRequest();
    xhr.open("DELETE", '/logout', true);
    xhr.setRequestHeader('Accept', 'application/javascript');
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        var script = document.createElement('script');
        script.text = xhr.response;
        document.head.appendChild(script).parentNode.removeChild(script);
      }
    };
    xhr.send();
  },
  getSessionInfo: function() {
    return {
      name: 'getSessionInfo',
      data: {
        cookieId: document.querySelector("meta[name='user-authentication']").getAttribute("data-cookie-id"),
        cookieValue: document.querySelector("meta[name='user-authentication']").getAttribute("data-cookie-value"),
        userId: document.querySelector("meta[name='user-authentication']").getAttribute("data-user-id"),
        userPersonalisation: JSON.parse(document.querySelector("meta[name='user-authentication']").getAttribute("data-user-personalisation")),
        consentURL: document.querySelector("meta[name='user-authentication']").getAttribute("data-consent-url")
      }
    };
  },
  loginSuccessful: function(userId) {
    this.postMessage({name: 'onLoginSuccessful', data: {userId: userId}})
  },
  buildMenu: function() {
    if (this.isNativeHandlerAvailable()) {
      var json = document.querySelector("meta[name='app-menu']").getAttribute("content");
      if (json.length) {
        var timestamp = document.querySelector("meta[name='app-menu']").getAttribute("data-timestamp");
        this.postMessage({name: 'buildMenu', data: {menu: json, timestamp: parseInt(timestamp)}})
      }
    }
  },
  hideBackButton: function() {
    var hideBackBtn = document.querySelector("meta[name='mobile_hide_back_button']").getAttribute("content");
    if (hideBackBtn != null) {
      return this.postMessage({
        name: 'hideBackButton',
        data: {
          result: hideBackBtn
        }
      });
    }

  },
  showInAppMessage: function() {},
  isOnBiometricLoginPage: function() {
    return {
      name: 'isOnBiometricLoginPage',
      data: {
        result: (document.querySelector("meta[name='biometric-login-page']").getAttribute("content") === 'true')
      }
    };
  },
  nuaBaseActions: function() {
    return ['pop', 'modal', 'reload', 'resetRoot', 'pushBack', 'hostedApp'];
  },
  nuaCompleteActions: function() {
    if (typeof nuaAdditionalActions !== "undefined" && nuaAdditionalActions !== null) {
      return nuaAdditionalActions.concat(this.nuaBaseActions());
    } else {
      return this.nuaBaseActions();
    }
  },
  getNuaAction: function(action) {
    if (this.nuaCompleteActions().indexOf(action) >= 0) {
      return action;
    } else {
      return this.fallbackForNuaAction(action);
    }
  },
  fallbackForNuaAction: function(action) {
    switch (action) {
      case 'login':
        return 'pop';
      default:
        return null;
    }
  },
  serialize: function(obj, prefix) {
    var str = [], p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          this.serialize(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  },
  registerFcmTokenForUser: function(firebaseToken, deviceUuid) {
    var tokenRegistrationPath = document.querySelector("meta[name='fcm-token-registration-path']").getAttribute("content");

    var xhr = new XMLHttpRequest();
    xhr.open("POST", tokenRegistrationPath, true);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        TurbolinksAdapter.postMessage({name: 'registerFcmTokenForUserResponse', data: {
          success: true,
          token: firebaseToken
        }});
      }
    };
    xhr.send(this.serialize({ fcm_registration_token: firebaseToken, device_uuid: deviceUuid }));
  },
  hitTag: function(tagName) {
    return this.postMessage({
      name: 'hitTag',
      data: {
        title: tagName
      }
    });
  }
};
