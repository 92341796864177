import FormController from "./concerns/form_controller"
import {EMAIL_FORMAT, format, presence} from "../helpers/validations_helper";
import Rails from '@rails/ujs';

export default class extends FormController {
  static targets = ['email', 'submitBtn', 'form']

  connect(){
    this.errorTemplate = this.element.querySelector('template#invalid-feedback-template').innerHTML;

    if (this.emailTarget && !this.emailTarget.dataset.validations)
      this.emailTarget.validations = [
        presence(true, {error: "Please enter a valid email address"}),
        format(EMAIL_FORMAT, {error: "Please enter a valid email address"})
      ];
  }

  onFormSubmit(e) {
    e.preventDefault();
    this._validateForm();
    if (this._formValid()) Rails.fire(this.formTarget, 'submit');
  }
}
