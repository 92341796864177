import { Controller } from 'stimulus';
export default class extends Controller {

  static targets = ['marketingBanner'];

  connect() {
    document.getElementById('notificationClose').addEventListener('click', this.closeNotification);
  }

  closeNotification = () => {
    fetch(this.data.get('dismissUrl'), {
      method: 'DELETE'
    });
    this.marketingBannerTarget.style.display = 'none';
  }
}