import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    this.element.addEventListener('ajax:before', this.onFormSubmission);
    this.element.addEventListener('ajax:complete', this.onFormAjaxComplete);
  }

  onFormSubmission = () => {
    const elementsToDisable = this.element.querySelectorAll('input[type=submit], button[type=submit]');

    for (let i = 0; i < elementsToDisable.length; i++) {
      this.disableElement(elementsToDisable[i]);
    }
  };

  onFormAjaxComplete = () => {
    const elementsToEnable = this.element.querySelectorAll('input[type=submit], button[type=submit]');

    for (let i = 0; i < elementsToEnable.length; i++) {
      this.enableElement(elementsToEnable[i]);
    }
  };

  disableElement = (element) => {
    element.disabled = true;
    element.setAttribute('data-disabled-on-submit', true);

    const disableWithValue = element.getAttribute('data-in-progress');

    if (disableWithValue && disableWithValue.length) {
      element.setAttribute('data-enable-with', this.getLabel(element));
      this.setLabel(element, disableWithValue);
    }
  };

  enableElement = (element) => {
    element.disabled = false;
    element.setAttribute('data-disabled-on-submit', false);

    const enableWithValue = element.getAttribute('data-enable-with');

    if (enableWithValue && enableWithValue.length) {
      // element.setAttribute('data-enable-with', this.getLabel(element));
      this.setLabel(element, enableWithValue);
    }
  };

  getLabel = (element) => {
    if (element.tagName == 'BUTTON' || element.tagName == 'A' ) {
      return element.innerHTML;
    } else if (element.tagName == 'INPUT') {
      return element.value;
    }
  };

  setLabel = (element, value) => {
    if (element.tagName == 'BUTTON' || element.tagName == 'A') {
      element.innerHTML = value;
    } else if (element.tagName == 'INPUT') {
      element.value = value;
    }
  };

}