import FormController from "./concerns/form_controller";
import { format, presence } from "../helpers/validations_helper";
import tippy from "tippy.js";

export default class extends FormController {
  static targets = ["verificationCode", "submitBtn", "form"];

  onMfaFormSubmit(e) {
    this._validateForm();
    if (this._formValid()) {
      let verificationCode = this.verificationCodeTarget.value;
      // If you want to use Turbolinks, uncomment the line below
      // and add method onMfaAttempt to TurbolinksAdapter
      // console.log(window.TurbolinksAdapter.onMfaAttempt(verificationCode));
    } else {
      e.preventDefault();
    }
  }

  connect() {
    this.errorTemplate = this.element.querySelector(
      "template#invalid-feedback-template"
    ).innerHTML;

    let tooltips = document.querySelectorAll(".special-tooltip");

    tooltips.forEach(tooltip => {
      tippy(tooltip, {
        content: tooltip.firstElementChild.textContent,
        placement: "bottom",
        arrow: true,
        theme: "lbt-tooltip",
        offset: [-45,9],
        maxWidth: '255px'
      });
    });

    if (
      this.verificationCodeTarget &&
      !this.verificationCodeTarget.dataset.validations
    ) {
      this.verificationCodeTarget.validations = [
        presence(true, {
          error: "Please enter a Verification code",
        }),
        format(/^[0-9]{6}$/, {
          error: "Verification code should be composed of 6 digits",
        }),
      ];
    }
    this.autofilledOTP();
  }

  _clearErrorsIfValid(event) {
    super._clearErrorsIfValid(event);
    if (event.target === this.verificationCodeTarget && event.target.isValid) {
      this.submitBtnTarget.disabled = false;
      this.submitBtnTarget.classList.remove("btn-disabled");
      this.submitBtnTarget.classList.add("btn-primary");
    }

    if (event.target === this.verificationCodeTarget && !event.target.isValid) {
      this.submitBtnTarget.disabled = true;
      this.submitBtnTarget.classList.add("btn-disabled");
      this.submitBtnTarget.classList.remove("btn-primary");
    }
  }

  // Enable submit button after OTP was autofilled
  autofilledOTP() {
    setInterval(() => {
      let input = this.verificationCodeTarget;
      if (input.value.length == input.maxLength) {
        this._validateForm();
        if (this._formValid()) {
          let fakeEvent = { target: input };
          this._clearErrorsIfValid(fakeEvent);
          // If you want to send the form after autofilling it
          // this.formTarget.submit();
        }
      }
    }, 1000);
  }
}

// This function is used for the Android App
function setOTP(otp) {
  document.getElementById("single-factor-code-text-field").value = otp
}
window.setOTP = setOTP;
