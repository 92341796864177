import {Controller} from "stimulus"

import { getValue} from '../../helpers/validations_helper';
import { debounce} from '../../helpers/application_helper';

export default class FormController extends Controller {
    toggleSubmitBtn() {
        if (!this.submitBtnTarget || this.submitBtnTarget.getAttribute('data-disabled-on-submit') == 'true') return;

        const inputs = this.element.querySelectorAll('input:not([type="submit"]):not([type=hidden]), textarea');
        const shouldEnable = Array.from(inputs).every((i) => !!i.isValid)
        this.submitBtnTarget.disabled = !shouldEnable;
    }

    validateField = debounce(this._validateInputField.bind(this), 0);
    clearErrorsOnFieldIfValid = debounce(this._clearErrorsIfValid.bind(this), 0);

    validatePasswordFields = function() {
        this._validateTarget(this.passwordTarget);
        if (this.password_confirmationTarget.value != "") {
            this._validateTarget(this.password_confirmationTarget);
        }
    };

    _validateTarget(target) {
        try {
            this._performValidations(target)
        } catch (e) {
            if (e.message) {
                target.insertAdjacentHTML('afterend', `${this.errorTemplate.replace('ERROR_MESSAGE', e.message)}`);
            }
            target.classList.add('is-invalid');
            target.parentNode.classList.remove('is-valid');
        }
    }

    _validateInputField(event) {
        console.log("_validateInputField")
        const {target} = event;
        this._validateTarget(target);
    }

    _clearErrorsIfValid(event) {
        console.log("_clearErrorsIfValid")
        if (event.type == 'keyup' && (event.which == 9 || event.keyCode == 9)) {
            return;
        }

        try {
            const inputs = this.element.querySelectorAll('input:not([type="submit"]):not([type=hidden]), textarea');
            for (var i = 0; i < inputs.length; ++i) {
                this._performValidations(inputs[i])
            }
        } catch {
            // Do nothing
        }
    }

    _performValidations(target) {
        const {validations = []} = {} = target;
        const value = getValue(target);

        target.isValid = false;

        const previousErrors = target.parentNode.querySelector('.invalid-feedback');
        if (previousErrors) previousErrors.remove();

        for (var i = 0; i < validations.length; ++i) {
            validations[i]({ value });
        }

        target.isValid = true;

        target.classList.remove('is-invalid');
        target.parentNode.classList.add('is-valid');
    }

    _validateForm() {
        try {
            const inputs = this.element.querySelectorAll('input:not([type="submit"]):not([type=hidden]), textarea');
            for (var i = 0; i < inputs.length; ++i) {
                this._validateTarget(inputs[i]);
            }
        } catch {
            // Do nothing
        }
    }

    _formValid() {
        const inputs = this.element.querySelectorAll('input:not([type="submit"]):not([type=hidden]), textarea');
        return Array.from(inputs).every((i) => !!i.isValid);
    }
}
