export const length = ({min = 0, max = Infinity}={}, {error = 'Invalid length'} = {}) =>
    ({value}) => (value && value.length >= min && value.length <= max)  || throw new Error(error);

export const presence = (presence = true, {error = 'Cant be blank'} = {}) =>
    ({value}) => (presence ? value : !value) || throw new Error(error);

export const EMAIL_FORMAT = /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2,})+$/i;
export const DATE_FORMAT = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
export const MEMBER_ID_FORMAT = /^[0-9]{10}$/;

export const format = (regex = /.+/s, {error = 'Invalid format'} = {}) =>
    ({value}) => {
      !!`${value || ''}`.match(regex) || throw new Error(error);
    }

export const boolean = (bool = true, {error = 'Must be true'} = {}) =>
    ({value}) => bool == !!value || throw new Error(error);

export const equals = (equality, {error = 'Is not equal'} = {}) =>
    ({value}) => (typeof equality == 'function' ? value == equality() : value == equality) || throw new Error(error);

export const older_than_16 = ({error = 'You must be over 16 to register'} = {}) =>
  ({value}) => {
      var dateParts = value.split("/");
      var dob = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      var today = new Date();
      var age = today.getFullYear() - dob.getFullYear();
      var m = today.getMonth() - dob.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
          age--;
      }
      age > 16 || throw new Error(error);
  }

export const validateField = (input, validations) => input.isValid = function(){
    try{
        input.errors = [];
        validations.every((func) => func({ value: getValue(this) }));
    }catch (e) {
        input.errors.push(e.message);
    }
}

export const getValue = (input) => {
    switch (input.type) {
        case "radio":
        case "checkbox":
            return input.checked;
        default:
            return input.value;
    }
}
