import FormController from "./concerns/form_controller"
import {EMAIL_FORMAT, format, getValue, presence} from "../helpers/validations_helper";
import Rails from '@rails/ujs';

export default class extends FormController {
  static targets = ['email', 'password', 'submitBtn', 'form'];

  onLoginFormSubmit(e) {
    this._validateForm();
    if (this._formValid()) {
      let email = this.emailTarget.value;
      let password = this.passwordTarget.value;
      console.log(window.TurbolinksAdapter.onLoginAttempt(email, password));
    } else {
      e.preventDefault();
    }
  };

  connect() {
    this.errorTemplate = this.element.querySelector('template#invalid-feedback-template').innerHTML;

    if (this.emailTarget && !this.emailTarget.dataset.validations)
      this.emailTarget.validations = [
        presence(true, {error: "Please enter a valid email address"}),
        format(EMAIL_FORMAT, {error: "Please enter a valid email address"})
      ];

    if (this.passwordTarget && !this.passwordTarget.dataset.validations)
      this.passwordTarget.validations = [
        presence(true, {error: "Please enter your password"}),
      ];

    this.element.addEventListener('ajax:complete', this.resetForm);
  };

  resetForm() {
    document.getElementById('login_form_email').value = '';
    document.getElementById('login_form_password').value = '';
  };
}
