export const replaceAt =  (string, index, replace) => string.substring(0, index) + replace + string.substring(index + 1)

export const debounce =  (func, wait = 100) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func.apply(this, args);
        }, wait);
    };
}