import FormController from "./concerns/form_controller"
import {format, equals, DATE_FORMAT, EMAIL_FORMAT, MEMBER_ID_FORMAT, older_than_16} from '../helpers/validations_helper';
import {replaceAt } from '../helpers/application_helper';
import Rails from '@rails/ujs';

function _safeLayaDateStr(str) {
    let out = str;

    if (out[0] && Number(out[0]) > 3) out = replaceAt(out, 0, `0${out[0]}`);
    if (out[1] && Number(out.substring(0, 2)) > 31) out = replaceAt(out, 1, '1');
    if (out[2] && Number(out[2]) > 1) out = replaceAt(out, 2, `0${out[2]}`);
    if (out[3] && Number(out.substring(2, 4)) > 12) out = replaceAt(out, 3, '2');

    return out;
}


export default class extends FormController {
    static targets = ['email', 'member_id', 'date_of_birth', 'password', 'password_confirmation', 'submitBtn', 'accept_terms', 'form']

    connect() {
        this.errorTemplate = this.element.querySelector('template#invalid-feedback-template').innerHTML;

        if (this.emailTarget && !this.emailTarget.dataset.validations)
            this.emailTarget.validations = [
                format(EMAIL_FORMAT, {error: "Please enter a valid email address"})
            ];

        if (this.member_idTarget && !this.member_idTarget.dataset.validations)
            this.member_idTarget.validations = [
                format(MEMBER_ID_FORMAT, {error: "Please enter a valid membership number"})
            ];

        if (this.date_of_birthTarget && !this.date_of_birthTarget.dataset.validations)
            this.date_of_birthTarget.validations = [
              format(DATE_FORMAT, {error: "Please enter a valid date of birth (dd/mm/yyyy)"}),
              older_than_16({error: "You must be over 16 to register"})
            ];

        if (this.passwordTarget && !this.passwordTarget.dataset.validations)
            this.passwordTarget.validations = [
                format(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/g, {error: "Please enter a valid password"})
            ];

        if (this.password_confirmationTarget && !this.password_confirmationTarget.dataset.validations)
            this.password_confirmationTarget.validations = [
                equals(() => this.passwordTarget.value, {error: "Please ensure passwords match"})
            ];

        if (this.accept_termsTarget && !this.accept_termsTarget.dataset.validations)
            this.accept_termsTarget.validations = [
                equals(true, {error: ""})
            ];
    }


    formatAsDate(ev) {
        if (ev.keyCode != 8) {
            const out = _safeLayaDateStr(ev.target.value.replace(/\D/g, ''));
            const len = out.length;

            if (len < 3) {
                ev.target.value = `${out.substring(0, 2)}/`;
            } else{
                ev.target.value = `${out.substring(0, 2)}/${out.substring(2, 4)}/${out.substring(4, 8)}`;
            }


            if (len < 2) {
                this.setCaretPosition(ev.target, len);
            } else if (len >= 4) {
                this.setCaretPosition(ev.target, len + 2);
            } else {
                this.setCaretPosition(ev.target, len + 1);
            }
        }
    }

    setCaretPosition(elem, caretPos) {
        if(elem != null) {
            if(elem.createTextRange) {
                var range = elem.createTextRange();
                range.move('character', caretPos);
                range.select();
            }
            else {
                if(elem.selectionStart) {
                    elem.focus();
                    elem.setSelectionRange(caretPos, caretPos);
                }
                else
                    elem.focus();
            }
        }
    }

    onFormSubmit(e) {
        e.preventDefault();
        this._validateForm();
        if (this._formValid()) Rails.fire(this.formTarget, 'submit');
    }
}
